
.ant-modal-body {
    padding: 10px!important;
}
.pd5{
    padding-left: 5px;
    padding-right: 5px;
}
.cashierWrap{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 30px;
        .listBox{
            list-style: none;
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
            font-size: 12px;
            .iconMindBox{
                width: 30px;
                height: 30px;
            }
            .headerCashierBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                box-sizing: border-box;
                padding-left: 15px;
                padding-right: 15px;
            }
            .defaultCashierBox{
                background: #FFFFFF;
                box-shadow: 0 2px 14px 0 rgba(0,0,0,0.15);
                border-radius: 6px;
                margin-top: 10px;
                align-items: center;
                box-sizing: border-box;

                .smallMidel{
                    width: 20px;
                    height: 20px;
                }
                .smallIcon{
                    width: 15px;
                    height: 15px;
                    margin-right: 15px;
                    cursor: pointer;
                }
                .importDesc{
                    background: rgba(255,47,61,0.3);
                    color: #FF2F3D;
                    height: 50px;
                }
                .yesDesc{
                    background: rgba(253,183,6,0.3);
                    color: #E4A400;
                    height: 50px;
                }
                .descriptionBox{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    .desImp{
                        color: #FF2F3D!important;
                    }
                    .desbox{
                        width: 260px;
                        font-size: 12px;
                        padding-left: 10px;
                        text-align: left;
                        box-sizing: border-box;
                    }
                }
                .cashierMidelBox{
                    display: flex;
                    align-items: flex-start;
                    box-sizing: border-box;
                    .defaultMoneyBox{
                        padding-left: 10px;
                        text-align: left;
                        font-size: 12px;
                        .activeMoneyNumber{
                            color: #FDB706;
                            font-weight: bold;
                        }
                        .defaultMoneyNumber{
                            color: #868686;
                            text-decoration: line-through;
                            padding-right: 10px;
                        }
                    }
                    .sloganBox{
                        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
                        font-weight: bold;
                        color: #000000;
                        font-size: 12px;
                        padding-left: 10px;
                        box-sizing: border-box;
                    }
                }
            }
            .listItemBox{
                display: flex;
                align-items: center;
                box-sizing: border-box;
                .itemTitle{
                    font-size: 14px;
                    font-family: HarmonyOS Sans-Medium, HarmonyOS Sans;
                    font-weight: bold;
                    color: #000000;
                    padding-left: 10px;
                }
                .iconBox{
                    width: 20px;
                    height: 20px;
                }
            }
        }
    .mt30{
        margin-top: 30px!important;
    }    
    .totalBox{
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        .totalMoneyBox{
            .totalMoney{
                font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
                font-weight: bold;
                color: #000000;
                font-size: 12px;
            }
            text-align: right;
            font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
            font-weight: 400;
            color: #8C8C8C;
        }
    }
    .lineBox{
        border-bottom: 1px dashed #8C8C8C;
        /* margin-top: 15px; */
        margin-bottom: 10px;
    }
    .tipBox{
        width: 343px;
        font-size: 12px;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #8C8C8C;
        padding: 12px;
        background-color: #D9D9D9;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        position: relative;
        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;  
            border-right: 6px solid transparent;  
            border-bottom: 10px solid #D9D9D9; 
            position: absolute;
            top: -7px;
            left: 40px;
        }
    }
    .tipBoxHeader{
        display: flex;
        align-items: center;
    }
    .tipIcon{
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-left: 10px;
    }
    .ptb40{
        padding: 10px 5px;
    }
    .flexSpaceBetween{
        display: flex;
        justify-content: space-between;
    }
    .footerWrap{
        width: 100%;
        background-color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        .footerBox{
            text-align: center;
            .subBtn{
                width: 240px;
                height: 35px;
                color: #fff;
                background-color: #335EF7;
                cursor: pointer;
            }
            .cannelBtn{
                width: 100px;
                height: 35px;
                color: #335EF7;
                border: 2px solid #335EF7;
                background-color: #fff;
                margin-right: 10px;
                cursor: pointer;
            }
            button {
                border: 0;
                border-radius: 7px;
            }
        }
    }
    
}
@primary-color: #FF4949;