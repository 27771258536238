* {
    margin: 0;
    padding: 0;
}

.section-box .left {
    font-size: 14px;
    width: 271px;
    box-sizing: border-box;
}

.section-box {
    background-color: #F9F9F9;
    width: 1075px;
    height: 300px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.banner-pic {
    width: 1075px;
}

.beian-icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
}

.footer-box span {
    padding: 0 20px;
}

.footer-box a {
    text-decoration: none;
    color: black;
}

.footer-box {
    text-align: center;
    font-size: 14px;
    padding: 20px;
    margin-top: 50px;
}

html,
body {
    background-color: #fff;
}

.app {
    width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
}

.logo {
    width: 109px;
    height: 19px;
}

.menu {
    list-style: none;
    display: flex;
}

.menu a {
    text-decoration: none;
    height: 14px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 14px;
}

.menu li {
    margin-right: 28px;
}

.header-box {
    padding: 20px 0;
}

.flex-box {
    display: flex;
    justify-content: space-between;
}
@primary-color: #FF4949;