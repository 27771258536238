* {
    padding: 0;
    margin: 0;
}
.flai-wrap{
    max-width: 1200px;
    min-width: 900px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    background: url('https://img.fanwoon.com/flaicontactus.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
   
}
@primary-color: #FF4949;