.App {
    position: relative;
    padding-top: 60px;
}

.job-name-box {
    width: 300px;
    cursor: pointer;
}

.logo-box {
    cursor: pointer;
}

.no-job-font {
    padding-bottom: 15px;
}

.no-job-list-box {
    text-align: center;
    padding: 30px 0;
}

.cursors {
    cursor: pointer;
}

.non-btn {
    color: #666!important;
    cursor: no-drop;
}

.pagination-box {
    text-align: right;
}

.important-box {
    font-family: 'PingFangSC-Regular', 'PingFang SC';
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #D9001B;
    text-align: center;
}

.footer-model {
    text-align: center;
    margin-top: 25px;
}

.a-span-btn {
    color: #FF4949;
    cursor: pointer;
    padding: 0 5px;
}

.introversion-btn-box {
    width: 100%;
    text-align: right;
    padding: 8px 0;
}

.code-img-box {
    text-align: center;
    padding-bottom: 20px;
}

.code-img {
    width: 100px;
    height: 35px;
    cursor: pointer;
}

.w80 {
    width: 80px;
}

.w100 {
    width: 100px;
}

.w292 {
    width: 292px;
}

.ver-input {
    border-right: 0;
}

.code-input-box {
    width: 682px;
}

.search-box {
    width: 400px;
    margin-bottom: 15px;
}

.account-page,
.company-page {
    max-width: 1980px;
    padding: 16px 38px;
    margin: 0 auto;
}

.m-r-16 {
    margin-right: 16px;
}

.m-lr-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.company-tool-bar {
    display: flex;
    justify-content: space-between;
}

.edite-page {
    max-width: 980px;
    margin: 0 auto;
}

.edite-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.edite-box-item-left {
    width: 120px;
}

.edite-box-item-right {
    width: 350px;
}

.address-local-box {
    display: flex;
}

.address-item {
    width: 300px;
    margin-right: 16px;
}

.title-blod {
    font-size: 16px;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #2c2c2c;
    margin: 10px 0;
}

.article-item-box {
    margin-bottom: 40px;
}

.form-footer-box {
    text-align: center;
    padding-bottom: 50px;
}

.login-page {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}


/*  />=1024的设备屏幕/ */

@media screen and (max-width: 1219px) {
    .logo1 {
        width: 530px!important;
    }
    .logo2 {
        width: 263px!important;
    }
    .logo3 {
        width: 159px!important;
    }
    /*  .login-form-box {
        top: 90px!important;
    } */
    .login-form-box input {
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
    }
    .vercode-btn {
        height: 40px;
    }
}


/* />=1100的设备屏幕/ */

@media screen and (min-width: 1220px) {
    /*  .login-form-box {
        top: 80px!important;
        right: 130px!important;
    } */
    .logo1 {
        width: 630px!important;
    }
    .logo2 {
        width: 363px!important;
    }
    .logo3 {
        width: 159px!important;
    }
    .login-form-box input {
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
    }
    .vercode-btn {
        height: 40px;
    }
}


/* />=1280的设备屏幕/ */

@media screen and (min-width: 1366px) {
    .login-form-box {
        top: 80px!important;
        right: 130px!important;
    }
    .logo1 {
        width: 640px!important;
    }
    .logo2 {
        width: 343px!important;
    }
    .logo3 {
        width: 159px!important;
    }
    .login-form-box input {
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
    }
    .vercode-btn {
        height: 40px;
    }
}


/* />=1366的设备屏幕/ */

@media screen and (min-width: 1440px) {
    .logo1 {
        width: 830px!important;
    }
    .logo2 {
        width: 563px!important;
    }
    .logo3 {
        width: 159px!important;
    }
    .login-form-box {
        top: 80px!important;
        right: 130px!important;
    }
    .login-form-box input {
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
    }
    .vercode-btn {
        height: 50px!important;
    }
    .oms-box {
        margin-bottom: 60px!important;
    }
    .queck-btn {
        height: 40px!important;
        line-height: 40px!important;
    }
}


/* />=1440的设备屏幕/ */

.logo1 {
    width: 630px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.logo2 {
    width: 363px;
    position: absolute;
    top: 149px;
    left: 92px;
    z-index: 3;
}

.logo3 {
    width: 159px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.pwd-box-taggle {
    margin-top: -10px;
}

.queck-btn {
    border: 2px solid #FF4949;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    margin-top: -10px;
    color: #FF4949;
    cursor: pointer;
}

.login-form-box {
    width: 410px;
    border-radius: 8px;
    position: absolute;
    right: 55px;
    top: 105px;
    z-index: 5;
    bottom: 143px;
}

.logo-box-header {
    text-align: center;
}

.label-mark {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1A110F;
    padding-bottom: 12px;
}

.vercode-btn {
    border-left: 0;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF4949;
    margin-left: -2px;
}

.oms-box {
    width: 160px;
    margin-bottom: 30px;
}

.oms-form-sub-box {
    padding: 0 40px;
    margin-top: 20px;
}

.footer-box-min {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.beian-icon {
    width: 20px;
}

.normal-a {
    color: #939393;
}

.login-form-btn {
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, #FF4949 0%, #F0664B 100%);
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    border: 2px solid #FF4949;
}

.footer-container {
    width: 650px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-container a {
    color: #939393;
}

.bei {
    width: 300px;
    margin: 0 auto;
    padding: 20px 0;
}

.switch-font {
    color: #FF4949;
    padding-bottom: 5px;
}

.ant-input:hover {
    border-color: #FF4949;
    border-right-width: 1px !important;
}

.flex-box {
    display: flex;
    justify-content: space-between;
}

.agreement-box {
    padding-top: 20px;
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    color: #636363;
}

.agreement-box a {
    color: #FF4949;
}

.ant-btn:hover,
.ant-btn:focus {
    color: #FF4949;
    background: #fff;
    border-color: #FF4949;
}

.cursor {
    cursor: pointer;
}

.reg-tooltip-box {
    margin-right: 10px;
}

.regibox {
    display: flex;
}