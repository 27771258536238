.fixBox {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}
.active-menu{
    color: #FF4949!important;
}
.pic-money-ico {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}
.font-underline {
    text-decoration: underline;
}
.font-money{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FE4A49;
    padding-right: 10px;
}
.font-money-num {
    cursor: pointer;
}

.edite-user-item{
    height: 35px;
    box-sizing: border-box;
}
.reset-sub-btn{
    margin-left: 70px;
    margin-right: 10px;
}
.menu-right-box{
    display: flex;
    align-items: center;
}
.name-info-box{
    display: flex;
    .left-box{
        flex: 1;
    }
    .right-box{
        margin-left: 10px;
        width: 120px;
    }
}
.avatar-info-box{
    cursor: pointer;
    position: relative;
    &:hover {
        .menu-box-list{
            height: auto;
            display: block;
        }
    }
    .menu-box-list{
        width: 100%;
        height: 0;
        display: none;
        position: absolute;
        top: 40px;
        z-index: 20;
        background-color: #fff;
        box-shadow: 0 2px 8px #f0f1f2;
        text-align: center;
        .menu-item{
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            border-bottom: 1px solid #ebebeb;
            &:hover{
                background-color: #FF4949;
                color: #fff;
                transition: all .35s ease;
            }
        }
    }
    .name{
        padding-right: 5px;
    }
}
.avatar-box{
    width: 40px;
    height: 40px;
    margin-right: 5px;
    img{
        border-radius: 50%;
        height: 100%;
    }
}
.header-box{
    max-width: 1980px;
    margin: 0 auto;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    .left-box{
        flex: 1;
        padding-top: 20px;
        a{
            color: #000000;
        }
    }

}
.logo-box{
    width: 120px;
    height: 45px;
    display: inline-block;
    margin-right: 30px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
@primary-color: #FF4949;