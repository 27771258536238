* {
    margin: 0;
    padding: 0;
}

.job-title {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF4949;
    padding-bottom: 5px;
}

.job-item-info {
    display: flex;
    justify-content: space-between;
}

.job-items {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555555;
    border-left: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    border-right: 1px solid #DEDEDE;
    padding: 10px 20px;
    cursor: pointer;
}

.hot-job {
    height: 31px;
    line-height: 31px;
    background: #F4F4F4;
    border-radius: 1px;
    border: 1px solid #DEDEDE;
    color: #FFA424;
    font-size: 12px;
    padding-left: 15px;
}

.beian-icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
}

.footer-box span {
    padding: 0 20px;
}

.footer-box a {
    text-decoration: none;
    color: black;
}

.footer-box {
    text-align: center;
    font-size: 14px;
    padding: 20px;
    margin-top: 50px;
}

html,
body {
    background-color: #fff;
}

.app {
    width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
}

.logo {
    width: 109px;
    height: 19px;
}

.menu {
    list-style: none;
    display: flex;
}

.menu a {
    text-decoration: none;
    height: 14px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 14px;
}

.menu li {
    margin-right: 28px;
}

.header-box {
    padding: 20px 0;
}

.flex-box {
    display: flex;
    justify-content: space-between;
}
@primary-color: #FF4949;