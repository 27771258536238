.follow-page{
    padding: 20px 50px;
    .square-box{
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        margin-left: 5px;
    }
    .no-job-search-info{
        padding-top: 60px;
        text-align: center;
    }
    .tab-search-bar{
        width: 180px;
        margin-bottom: 10px;
    }
    .saixuan-header-box{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
       
    }
    .job-name-bar{
        width: 200px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        .city-box{
            padding-left: 10px;
        }
    }
    .close-square{
        background-color: #bfbfbf;
    }
    .success-square{
        background-color: #80c75d;
    }
    .item-square{
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right:4px;
    }
    .job-state-box{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #767676;
    }
    .follow-content{
        display: flex;
        width: 100%;
        .left-box{
            width: 260px;
            height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;
            margin-right: 50px;
            box-sizing: border-box;
            .mark-slogan{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                padding-bottom: 20px;
            }
        }
        .right-box{
            flex: 1;
            box-sizing: border-box;
            .job-state{ 
                font-size: 12px;
                padding-right: 10px;
                padding-left: 10px;
            }
            .create-time-box{
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #767676;
            }
            .job-info-box{
                margin-bottom: 20px;
                .name {
                    font-size: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                .money{
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFA100;
                    padding-left: 20px;
                }
            }
        }
    }
    .job-list-left{
        display: flex;
        flex-direction: column;
        .active{
            border-right: 4px solid #FF4949;
        } 
        .list-item{
            height: 80px;
            border-bottom: 1px solid #f0f0f0;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 15px;
            box-sizing: border-box;
            cursor: pointer;
        }
    }
}
.lin-box{
    border-bottom: 1px solid #f0f0f0;
}
.avatar-item{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
}
.avatar-small-item{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.follow-flex{
    display: flex;
    padding:  6px 0;
}

@primary-color: #FF4949;